"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestGiftStatusTrans = exports.RequestGiftStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var RequestGiftStatus;
(function (RequestGiftStatus) {
  RequestGiftStatus["Pending"] = "PENDING";
  RequestGiftStatus["Processing"] = "PROCESSING";
  RequestGiftStatus["Success"] = "SUCCESS";
  RequestGiftStatus["Fail"] = "FAIL";
})(RequestGiftStatus || (exports.RequestGiftStatus = RequestGiftStatus = {}));
var requestGiftStatusTrans = exports.requestGiftStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, RequestGiftStatus.Pending, {
  label: "Chờ xử lý",
  value: RequestGiftStatus.Pending,
  color: "info"
}), RequestGiftStatus.Processing, {
  label: "Đang xử lý",
  value: RequestGiftStatus.Processing,
  color: "warning"
}), RequestGiftStatus.Success, {
  label: "Thành công",
  value: RequestGiftStatus.Success,
  color: "success"
}), RequestGiftStatus.Fail, {
  label: "Thất bại",
  value: RequestGiftStatus.Fail,
  color: "danger"
});