"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loopTypeTrans = exports.LoopType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var LoopType;
(function (LoopType) {
  LoopType["Day"] = "DAY";
  LoopType["Week"] = "WEEK";
  LoopType["Month"] = "MONTH";
  LoopType["Year"] = "YEAR";
})(LoopType || (exports.LoopType = LoopType = {}));
var loopTypeTrans = exports.loopTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, LoopType.Day, {
  label: "Hàng ngày",
  value: LoopType.Day
}), LoopType.Week, {
  label: "Hàng tuần",
  value: LoopType.Week
}), LoopType.Month, {
  label: "Hàng tháng",
  value: LoopType.Month
}), LoopType.Year, {
  label: "Hàng năm",
  value: LoopType.Year
});