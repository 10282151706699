"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notificationConfigTrans = exports.NotificationConfigType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var NotificationConfigType;
(function (NotificationConfigType) {
  NotificationConfigType["SlimmingPillow"] = "SLIMMING_PILLOW";
})(NotificationConfigType || (exports.NotificationConfigType = NotificationConfigType = {}));
var notificationConfigTrans = exports.notificationConfigTrans = (0, _defineProperty2.default)({}, NotificationConfigType.SlimmingPillow, "Angela sẽ thường xuyên nhắc nhở bạn chế độ sinh hoạt điều độ để giảm cân. Hãy nhấn vào đây để tùy chỉnh cho phù hợp với bạn");