"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentTypeTrans = exports.PaymentType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var PaymentType;
(function (PaymentType) {
  PaymentType["AlePay"] = "ALE_PAY";
  PaymentType["Google"] = "GOOGLE";
  PaymentType["Apple"] = "APPLE";
  PaymentType["PayPal"] = "PAYPAL";
  PaymentType["ViSedu"] = "VISEDU";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var PaymentTypeTrans = exports.PaymentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, PaymentType.AlePay, "AlePay"), PaymentType.Google, "Google"), PaymentType.Apple, "Apple"), PaymentType.PayPal, "PayPal"), PaymentType.ViSedu, "ViSedu");