"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.keys.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày tạo")]), _c("el-date-picker", {
    attrs: {
      type: "daterange",
      size: "normal",
      "range-separator": "-",
      "start-placeholder": "",
      format: "dd/MM/yyyy",
      "end-placeholder": "",
      clearable: ""
    },
    on: {
      change: _vm.fetchSurveyForm,
      clear: _vm.clearFilterDate
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người dùng")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: "",
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      placeholder: "Tìm kiếm người dùng",
      "remote-method": _vm.searchStaffs,
      loading: _vm.loadingStaffs
    },
    on: {
      change: _vm.handleChangeFilter,
      clear: _vm.handleClearSelect
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: _vm.truncateText(item.name, 25),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Gói sản phẩm")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: "",
      filterable: ""
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "bookId", $$v);
      },
      expression: "query.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchSurveyForm
    }
  }, [_vm._v("Tìm kiếm")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-bottom"
    },
    on: {
      click: _vm.showModal
    }
  }, [_vm._v("Xuất file (Excel)")])], 1), _c("el-dialog", {
    attrs: {
      title: "Nhập số trang",
      visible: _vm.isModalVisible,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isModalVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.pageRange
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Từ trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.from,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "from", _vm._n($$v));
      },
      expression: "pageRange.from"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Đến trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.to,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "to", _vm._n($$v));
      },
      expression: "pageRange.to"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.isModalVisible = false;
      }
    }
  }, [_vm._v("Hủy")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitPageRange
    }
  }, [_vm._v("Xác nhận")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.surveyForm,
      border: "",
      fit: "",
      height: "500"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Người dùng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _row$user, _row$user2, _row$user3, _row$user4, _row$user5, _row$user6, _row$user7, _row$user8, _row$user9, _row$user10, _row$user11, _row$user12, _row$user13, _row$user14, _row$user15, _row$user16, _row$user17, _row$user18, _row$user19, _row$user20, _row$user21, _row$user22;
        var row = _ref.row;
        return [_c("div", [_c("div", {
          staticStyle: {
            "margin-bottom": "10px"
          }
        }, [_c("img", {
          staticStyle: {
            "border-radius": "50%",
            "object-fit": "cover",
            margin: "0 auto",
            display: "block"
          },
          attrs: {
            width: "60",
            height: "60",
            src: row !== null && row !== void 0 && (_row$user = row.user) !== null && _row$user !== void 0 && _row$user.avatar ? _vm.$url.image(row.user.avatar) : _vm.avatarDefault,
            alt: ""
          }
        })]), row !== null && row !== void 0 && (_row$user2 = row.user) !== null && _row$user2 !== void 0 && _row$user2.name ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Họ tên: ")]), _c("span", [_vm._v(_vm._s(row === null || row === void 0 || (_row$user3 = row.user) === null || _row$user3 === void 0 ? void 0 : _row$user3.name))])]) : _vm._e(), row !== null && row !== void 0 && (_row$user4 = row.user) !== null && _row$user4 !== void 0 && _row$user4.username ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row === null || row === void 0 || (_row$user5 = row.user) === null || _row$user5 === void 0 ? void 0 : _row$user5.username))])]) : _vm._e(), (_row$user6 = row.user) !== null && _row$user6 !== void 0 && _row$user6.phone ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số điện thoại: ")]), _c("span", [_vm._v(_vm._s((_row$user7 = row.user) === null || _row$user7 === void 0 ? void 0 : _row$user7.phone))])]) : _vm._e(), (_row$user8 = row.user) !== null && _row$user8 !== void 0 && _row$user8.email ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), _c("span", [_vm._v(_vm._s((_row$user9 = row.user) === null || _row$user9 === void 0 ? void 0 : _row$user9.email))])]) : _vm._e(), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Nguồn: ")]), _c("span", [_vm._v(_vm._s(((_row$user10 = row.user) === null || _row$user10 === void 0 ? void 0 : _row$user10.createFrom) == _vm.CreateFrom.Self ? "Portal" : "Sync"))])]), (_row$user11 = row.user) !== null && _row$user11 !== void 0 && _row$user11.azureId ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Azure Id: ")]), _c("span", [_vm._v(" " + _vm._s((_row$user12 = row.user) === null || _row$user12 === void 0 ? void 0 : _row$user12.azureId) + " ")])]) : _vm._e(), (_row$user13 = row.user) !== null && _row$user13 !== void 0 && _row$user13.azureEmail ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Azure email: ")]), _c("span", [_vm._v(_vm._s((_row$user14 = row.user) === null || _row$user14 === void 0 ? void 0 : _row$user14.azureEmail))])]) : _vm._e(), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Giới tính: ")]), (_row$user15 = row.user) !== null && _row$user15 !== void 0 && _row$user15.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[(_row$user16 = row.user) === null || _row$user16 === void 0 ? void 0 : _row$user16.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ngày sinh: ")]), (_row$user17 = row.user) !== null && _row$user17 !== void 0 && _row$user17.dob ? _c("span", [_vm._v(_vm._s((_row$user18 = row.user) === null || _row$user18 === void 0 ? void 0 : _row$user18.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Zalo: ")]), (_row$user19 = row.user) !== null && _row$user19 !== void 0 && _row$user19.zalo ? _c("span", [_vm._v(_vm._s((_row$user20 = row.user) === null || _row$user20 === void 0 ? void 0 : _row$user20.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Facebook: ")]), (_row$user21 = row.user) !== null && _row$user21 !== void 0 && _row$user21.facebook ? _c("span", [_vm._v(_vm._s((_row$user22 = row.user) === null || _row$user22 === void 0 ? void 0 : _row$user22.facebook))]) : _c("span", [_vm._v("--")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Gói sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [row.book ? _c("span", [_vm._v(_vm._s(row.book.name))]) : _c("span", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Câu hỏi và câu trả lời"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("label", [_vm._v("Khảo sát số " + _vm._s(row.currentOrderNumber + 1))]), _c("br"), _c("div", [_c("label", [_vm._v("Câu hỏi: ")]), _c("span", [_vm._v(_vm._s(JSON.parse(row.jsonDataGeneral)[0].question))])]), _c("div", [_c("label", [_vm._v("Câu trả lời: ")]), _vm._l(JSON.parse(row.jsonDataGeneral)[0].quizChoices, function (quizChoice) {
          return _c("span", [_vm._v(_vm._s(quizChoice.choice))]);
        })], 2), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-view"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleOpenDetailQuestion(row.jsonDataGeneral);
            }
          }
        }, [_vm._v("Xem chi tiết")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchSurveyForm
    }
  }), _c("SurveyCampaignAnswerModal", {
    ref: "SurveyCampaignAnswerModal",
    on: {
      submitOk: _vm.fetchSurveyForm
    }
  }), _c("QuestionDetailModal", {
    ref: "QuestionDetailModal"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;