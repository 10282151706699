"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _staff = require("@/types/staff");
_vue.default.use(_vueRouter.default);
/* Layout */

/* Router Modules */
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth/index.vue"));
    });
  },
  name: "auth",
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/public/highlight-book/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/public-highlight-book-strategy/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/auth/azure",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth-azure/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _index.default,
  redirect: "/dashboard/index",
  meta: {
    title: "Dashboard",
    icon: "chart",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/admin/index.vue"));
      });
    },
    name: "dashboard-list",
    meta: {
      title: "Dashboard",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }]
}, {
  path: "/book",
  component: _index.default,
  redirect: "/book/index",
  meta: {
    title: "Quản lý sách",
    icon: "dice-d6",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "view-media",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book/index.vue"));
      });
    },
    name: "book-view-media",
    hidden: true,
    meta: {
      title: "Xem link media",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book/index.vue"));
      });
    },
    name: "book-list",
    meta: {
      title: "Đã phát hành",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "un-publish",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book/BookUnPublish.vue"));
      });
    },
    name: "book-un-pubish",
    meta: {
      title: "Chưa phát hành",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "sync",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sync-book/index.vue"));
      });
    },
    name: "sync-book",
    meta: {
      title: "Đồng bộ sách",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "sync-v2",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sync-book-v2/index.vue"));
      });
    },
    name: "sync-book-v2",
    meta: {
      title: "Nhập sách theo lô",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "sync-opac",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/opac-book/index.vue"));
      });
    },
    name: "sync-book-opac",
    meta: {
      title: "Đồng bộ sách giấy",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book-category/index.vue"));
      });
    },
    name: "book-category",
    meta: {
      title: "Thể loại sách",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "author",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/author/index.vue"));
      });
    },
    name: "book-author",
    roles: [_staff.StaffType.Staff],
    meta: {
      title: "Tác giả",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "warehouse",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/warehouse/index.vue"));
      });
    },
    name: "book-warehouse",
    roles: [_staff.StaffType.Staff],
    meta: {
      title: "Kho",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/highlight-strategy",
  component: _index.default,
  redirect: "/highlight-strategy/all",
  meta: {
    title: "Thu hút độc giả",
    icon: "dice-d6",
    permission: true,
    roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian]
  },
  children: [{
    path: "all",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/highlight-book-strategy/index.vue"));
      });
    },
    name: "highlight-strategy-all",
    meta: {
      title: "Tất cả",
      roles: [_staff.StaffType.Staff],
      permission: true
    }
  }, {
    path: "pending",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/highlight-book-strategy/pending.vue"));
      });
    },
    name: "highlight-strategy-pending",
    meta: {
      title: "Chờ duyệt",
      roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian],
      permission: true
    }
  }]
}, {
  path: "/recommend-strategy",
  component: _index.default,
  redirect: "/recommend-strategy/all",
  meta: {
    title: "Sách khuyến nghị",
    icon: "dice-d6",
    permission: true,
    roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian]
  },
  children: [{
    path: "all",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend-book-strategy/index.vue"));
      });
    },
    name: "recommend-strategy-all",
    meta: {
      roles: [_staff.StaffType.Staff],
      title: "Tất cả",
      permission: true
    }
  }, {
    path: "pending",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend-book-strategy/pending.vue"));
      });
    },
    name: "recommend-strategy-pending",
    meta: {
      title: "Chờ duyệt",
      roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian],
      permission: true
    }
  }]
},
// {
//   path: "/news",
//   component: Layout,
//   meta: {
//     title: "Tin tức",
//     icon: "newspaper",
//     permission: true,
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/news/index.vue"),
//       name: "news-index",
//       meta: {
//         title: "Tin tức",
//         permission: true,
//       },
//     },
//   ],
// },
// {
//   path: "/department",
//   component: Layout,
//   meta: {
//     title: "Quản lý bộ lọc",
//     icon: "briefcase",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/department/index.vue"),
//       name: "department-index",
//       meta: {
//         title: "Quản lý bộ lọc",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
{
  path: "/heatmap",
  component: _index.default,
  meta: {
    title: "Mật độ bạn đọc",
    icon: "briefcase",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/heatmap/index.vue"));
      });
    },
    name: "heatmap",
    meta: {
      title: "Mật độ bạn đọc",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/user-manual",
  component: _index.default,
  meta: {
    title: "Video hướng dẫn",
    icon: "briefcase",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user-manual/index.vue"));
      });
    },
    name: "user-manual",
    meta: {
      title: "Video hướng dẫn",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/episode-package",
  component: _index.default,
  meta: {
    title: "Gói tập",
    icon: "briefcase",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/episode-package/index.vue"));
      });
    },
    name: "episode-package",
    meta: {
      title: "Gói tập",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/advertisement",
  component: _index.default,
  redirect: "/advertisement/user",
  meta: {
    title: "Quảng cáo",
    icon: "users",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "user",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/advertisement/user.vue"));
      });
    },
    name: "user-advertisement",
    meta: {
      title: "Người dùng",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "content",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/advertisement/content.vue"));
      });
    },
    name: "content-advertisement",
    meta: {
      title: "Nội dung",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
},
/*
{
  path: "/opac",
  component: Layout,
  meta: {
    title: "Thư viện điện tử",
    icon: "file-invoice",
    permission: true,
    roles: [StaffType.Staff],
  },
  children: [
    {
      path: "index",
      component: () => import("@/views/opac/index.vue"),
      name: "opac",
      meta: {
        title: "Thư viện điện tử",
        permission: true,
        roles: [StaffType.Staff],
      },
    },
  ],
},
*/
{
  path: "/device-manage",
  component: _index.default,
  meta: {
    title: "Quản lý thiết bị",
    icon: "box-open-solid",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/device-manage/index.vue"));
      });
    },
    name: "device-index",
    meta: {
      title: "Danh sách thiết bị",
      permission: true
    }
  }, {
    path: "mac-address",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mac-address/index.vue"));
      });
    },
    name: "device-mac-index",
    meta: {
      title: "Địa chỉ MAC",
      permission: true
    }
  }]
}, {
  path: "/order",
  component: _index.default,
  meta: {
    title: "Đơn hàng",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/index.vue"));
      });
    },
    name: "order-index",
    meta: {
      title: "Đơn hàng",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/physical-order",
  component: _index.default,
  meta: {
    title: "Đơn gói sản phẩm",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/physical-order/index.vue"));
      });
    },
    name: "physical-order-index",
    meta: {
      title: "Đơn gói sản phẩm",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/notification",
  component: _index.default,
  meta: {
    title: "Thông báo",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/notification/index.vue"));
      });
    },
    name: "notification-index",
    meta: {
      title: "Thông báo App",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/banner",
  component: _index.default,
  meta: {
    title: "Banner",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner/index.vue"));
      });
    },
    name: "banner-index",
    meta: {
      title: "Banner",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/request-gift",
  component: _index.default,
  meta: {
    title: "Yêu cầu đổi quà",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/request-gift/index.vue"));
      });
    },
    name: "request-gift-index",
    meta: {
      title: "Yêu cầu đổi quà",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/transaction",
  component: _index.default,
  meta: {
    title: "Lịch sử ví user",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transaction/index.vue"));
      });
    },
    name: "transaction-index",
    meta: {
      title: "Lịch sử ví user",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/mobile-app",
  component: _index.default,
  meta: {
    title: "Mobile app",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mobile-app/index.vue"));
      });
    },
    name: "mobile-app-list",
    meta: {
      title: "Tất cả",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "content-define",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content-define/index.vue"));
      });
    },
    name: "content-define-index",
    meta: {
      title: "Nội dung App",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
},
// {
//   path: "/management-form",
//   component: Layout,
//   meta: {
//     title: "Quản lý form",
//     icon: "table",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "survey-form",
//       component: () => import("@/views/management-form/index.vue"),
//       name: "survey-form",
//       meta: {
//         title: "Form khảo sát",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
{
  path: "/management-form-ai",
  component: _index.default,
  meta: {
    title: "Khảo sát tập luyện",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "survey-campaign",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/management-form-ai/index.vue"));
      });
    },
    name: "survey-campaign",
    meta: {
      title: "Cấu hình lựa chọn",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "survey-campaign-answer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/survey-campaign-answer/index.vue"));
      });
    },
    name: "survey-campaign-answer",
    meta: {
      title: "DS câu trả lời",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/management-survey-combo-package",
  component: _index.default,
  meta: {
    title: "Khảo sát mua gói",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "survey-combo-package",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/survey-combo-package/index.vue"));
      });
    },
    name: "survey-combo-package",
    meta: {
      title: "DS câu trả lời",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/configuration",
  component: _index.default,
  redirect: "/configuration/index",
  meta: {
    title: "Cấu hình",
    icon: "settings",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "configuration-index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/configuration/index.vue"));
      });
    },
    name: "configuration-index",
    meta: {
      title: "Cấu hình chung",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "message-event",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/message-event/index.vue"));
      });
    },
    name: "message-event",
    meta: {
      title: "Cấu hình lời nhắc",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "reminder-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reminder-config/index.vue"));
      });
    },
    name: "reminder-config",
    meta: {
      title: "Lời nhắc gói",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "contact-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/contact-config/index.vue"));
      });
    },
    name: "contact-config",
    meta: {
      title: "Liên hệ",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "expert",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/expert/index.vue"));
      });
    },
    name: "expert",
    meta: {
      title: "Chuyên gia",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "quick-chat",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quick-chat/index.vue"));
      });
    },
    name: "quick-chat",
    meta: {
      title: "Quick Chat",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "survey-form",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/management-form/index.vue"));
      });
    },
    name: "survey-form",
    meta: {
      title: "Form khảo sát",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "survey-form-package",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/management-form-package/index.vue"));
      });
    },
    name: "survey-form-package",
    meta: {
      title: "Form khảo sát gói",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "music",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/music/index.vue"));
      });
    },
    name: "music",
    meta: {
      title: "Nhạc nền",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "gift",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/gift/index.vue"));
      });
    },
    name: "gift",
    meta: {
      title: "Quà tặng",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
},
// {
//   path: "/message-event",
//   component: Layout,
//   redirect: "/message-event/index",
//   meta: {
//     title: "Cấu hình lời nhắc",
//     icon: "settings",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "message-event",
//       component: () => import("@/views/message-event/index.vue"),
//       name: "message-event",
//       meta: {
//         title: "Cấu hình lời nhắc",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
{
  path: "/commission",
  component: _index.default,
  redirect: "/commission/index",
  meta: {
    title: "NPP",
    icon: "settings",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "commission-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/commission-config/index.vue"));
      });
    },
    name: "commission-config",
    meta: {
      title: "Cấu hình hoa hồng",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  },
  // {
  //   path: "record-sale",
  //   component: () => import("@/views/record-sale/index.vue"),
  //   name: "record-sale-index",
  //   meta: {
  //     title: "Thống kê bán hàng",
  //     permission: true,
  //     roles: [StaffType.Staff],
  //   },
  // },
  {
    path: "refHistory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/refHistory/index.vue"));
      });
    },
    name: "refHistory-index",
    meta: {
      title: "Lịch sử chia sẻ",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "npp",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/npp/index.vue"));
      });
    },
    name: "npp-index",
    meta: {
      title: "Thống kê theo NPP",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
},
// {
//   path: "/commission-config",
//   component: Layout,
//   redirect: "/commission-config/index",
//   meta: {
//     title: "Cấu hình hoa hồng",
//     icon: "settings",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "commission-config",
//       component: () => import("@/views/commission-config/index.vue"),
//       name: "commission-config",
//       meta: {
//         title: "Cấu hình hoa hồng",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
// {
//   path: "/record-sale",
//   component: Layout,
//   redirect: "/record-sale/index",
//   meta: {
//     title: "Thống kê bán hàng",
//     icon: "shopping",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "list",
//       component: () => import("@/views/record-sale/index.vue"),
//       name: "record-sale-index",
//       meta: {
//         title: "Thống kê bán hàng",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
// {
//   path: "/refHistory",
//   component: Layout,
//   redirect: "/refHistory/index",
//   meta: {
//     title: "Lịch sử chia sẻ",
//     icon: "table",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "list",
//       component: () => import("@/views/refHistory/index.vue"),
//       name: "refHistory-index",
//       meta: {
//         title: "Lịch sử chia sẻ",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
// {
//   path: "/contact-config",
//   component: Layout,
//   redirect: "/contact-config/index",
//   meta: {
//     title: "Liên hệ",
//     icon: "users",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "list",
//       component: () => import("@/views/contact-config/index.vue"),
//       name: "contact-config-index",
//       meta: {
//         title: "Liên hệ",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
// {
//   path: "/expert",
//   component: Layout,
//   redirect: "/expert/index",
//   meta: {
//     title: "Chuyên gia",
//     icon: "users",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "list",
//       component: () => import("@/views/expert/index.vue"),
//       name: "expert-index",
//       meta: {
//         title: "Chuyên gia",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
// {
//   path: "/quick-chat",
//   component: Layout,
//   redirect: "/quick-chat/index",
//   meta: {
//     title: "Quick Chat",
//     icon: "message",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "list",
//       component: () => import("@/views/quick-chat/index.vue"),
//       name: "quick-chat-index",
//       meta: {
//         title: "Quick Chat",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
{
  path: "/user",
  component: _index.default,
  redirect: "/user/index",
  meta: {
    title: "Người dùng",
    icon: "users",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/staff/index.vue"));
      });
    },
    name: "user-list",
    meta: {
      title: "Tất cả",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "active",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/staff/active.vue"));
      });
    },
    name: "user-list-active",
    meta: {
      title: "Đã kích hoạt",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "in-active",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/staff/inactive.vue"));
      });
    },
    name: "user-list-inactive",
    meta: {
      title: "Chưa kích hoạt",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
},
// {
//   path: "/npp",
//   component: Layout,
//   redirect: "/npp/index",
//   meta: {
//     title: "Thống kê theo NPP",
//     icon: "users",
//     permission: true,
//     roles: [StaffType.Staff],
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/npp/index.vue"),
//       name: "npp-index",
//       meta: {
//         title: "Thống kê theo NPP",
//         permission: true,
//         roles: [StaffType.Staff],
//       },
//     },
//   ],
// },
{
  path: "/monitor",
  component: _index.default,
  redirect: "/monitor/index",
  meta: {
    title: "Monitor",
    icon: "users",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/monitor/index.vue"));
      });
    },
    name: "monitor-index",
    meta: {
      title: "Monitor",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/rate",
  component: _index.default,
  redirect: "/rate/index",
  meta: {
    title: "Đánh giá",
    icon: "star",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/rate/index.vue"));
      });
    },
    name: "rate-index",
    meta: {
      title: "Đánh giá",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/popup",
  component: _index.default,
  redirect: "/popup/index",
  meta: {
    title: "Popup",
    icon: "table",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/popup/index.vue"));
      });
    },
    name: "popup-index",
    meta: {
      title: "Popup",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/role",
  component: _index.default,
  redirect: "/role/index",
  meta: {
    title: "Phân quyền",
    icon: "fire-fill",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role.vue"));
      });
    },
    name: "role-index",
    meta: {
      title: "Phân quyền",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/logs",
  component: _index.default,
  redirect: "/logs/kiosk",
  meta: {
    title: "Log",
    icon: "fire-fill",
    permission: true,
    roles: [_staff.StaffType.Staff]
  },
  children: [{
    path: "kiosk",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/log/kiosk.vue"));
      });
    },
    name: "logs-kiosk",
    meta: {
      title: "Log Kiosk",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }, {
    path: "yoga",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/log/yoga.vue"));
      });
    },
    name: "logs-yoga",
    meta: {
      title: "Log Yoga",
      permission: true,
      roles: [_staff.StaffType.Staff]
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  alwayShow: true,
  meta: {
    title: "Cá nhân",
    icon: "qq",
    permission: false,
    roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Cá nhân",
    alwayShow: true,
    meta: {
      title: "Cá nhân",
      permission: false,
      roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian]
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
}
var _default = exports.default = router;