"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessageEventTypeTrans = exports.MessageEventType = exports.MessageEventDonePracticeTypeTrans = exports.MessageEventDonePracticeType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MessageEventType;
(function (MessageEventType) {
  MessageEventType["WhenDonePracticeVideo"] = "WHEN_DONE_PRACTICE_VIDEO";
  MessageEventType["WhenDonePracticeAI"] = "WHEN_DONE_PRACTICE_AI";
  MessageEventType["WhenPractice3TimesVideo"] = "WHEN_PRACTICE_3_TIMES_VIDEO";
  MessageEventType["WhenViewPast"] = "WHEN_VIEW_PASTE";
  MessageEventType["WhenViewNext"] = "WHEN_VIEW_NEXT";
  MessageEventType["TutorialCapture"] = "TUTORIAL_CAPTURE";
  MessageEventType["DoneRoadmap"] = "DONE_ROADMAP";
})(MessageEventType || (exports.MessageEventType = MessageEventType = {}));
var MessageEventTypeTrans = exports.MessageEventTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MessageEventType.WhenDonePracticeVideo, "Mess khi người dùng tập xong video thường"), MessageEventType.WhenDonePracticeAI, "Mess động viên sau khi tập với AI"), MessageEventType.WhenPractice3TimesVideo, "Mess khi người dùng tập với video thường 3 lần nhắc tập với trợ lý ảo"), MessageEventType.WhenViewPast, "Mess khi người dùng xem bài tập quá khứ  trong lộ trình"), MessageEventType.WhenViewNext, "Mess khi người dùng xem bài tập tương lai trong lộ trình"), MessageEventType.TutorialCapture, "Hướng dẫn chụp ảnh"), MessageEventType.DoneRoadmap, "Hoàn thành lộ trình");
var MessageEventDonePracticeType;
(function (MessageEventDonePracticeType) {
  MessageEventDonePracticeType["Type50"] = "50";
  MessageEventDonePracticeType["Type70"] = "70";
  MessageEventDonePracticeType["Type100"] = "100";
})(MessageEventDonePracticeType || (exports.MessageEventDonePracticeType = MessageEventDonePracticeType = {}));
var MessageEventDonePracticeTypeTrans = exports.MessageEventDonePracticeTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MessageEventDonePracticeType.Type50, "Tập từ 50 - 100%"), MessageEventDonePracticeType.Type70, "Tập từ 50 - 100%"), MessageEventDonePracticeType.Type100, "Tập từ 50 - 100%");