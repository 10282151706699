"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.oneSignalApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var oneSignalApi = exports.oneSignalApi = {
  sub: function sub(data) {
    return (0, _request.default)({
      url: "/v1/admin/oneSignal/sub",
      data: data,
      method: "post"
    });
  },
  unSub: function unSub(data) {
    return (0, _request.default)({
      url: "/v1/admin/oneSignal/unSub",
      data: data,
      method: "delete"
    });
  }
};