"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.request = exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.object.to-string.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
var service = exports.request = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});
// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  config.headers["token"] = (0, _auth.getToken)();
  return config;
}, function (error) {
  // do something with request error
  // eslint-disable-next-line no-console
  console.log(error); // for debug
  return Promise.reject(error);
});
// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */
/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (response.status !== 200) {
    (0, _elementUi.Message)({
      message: res.message || "Error",
      type: "error",
      duration: 5 * 1000
    });
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (response.status === 50008 || response.status === 50012 || response.status === 50014) {
      // to re-login
      _elementUi.MessageBox.confirm("You have been logged out, you can cancel to stay on this page, or log in again", "Confirm logout", {
        confirmButtonText: "Re-Login",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        _store.default.dispatch("user/resetToken").then(function () {
          location.reload();
        });
      });
    }
    return Promise.reject(new Error(res.message || "Error"));
  } else {
    return res;
  }
}, function (error) {
  var status = error.response ? error.response.status : false;
  if (status) {
    (0, _elementUi.Message)({
      message: error.response.data.message,
      type: "error",
      duration: 5 * 1000
    });
  } else {
    (0, _elementUi.Message)({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
var _default = exports.default = service;