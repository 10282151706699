"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SurveyCampaignTypeTrans = exports.SurveyCampaignType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var SurveyCampaignType;
(function (SurveyCampaignType) {
  SurveyCampaignType["PracticeVideo"] = "PRACTICE_VIDEO";
  SurveyCampaignType["PracticeAI"] = "PRACTICE_AI";
  SurveyCampaignType["OpenApp"] = "OPEN_APP";
  SurveyCampaignType["Custom"] = "CUSTOM";
  SurveyCampaignType["SurveyBuyPack"] = "SURVEY_BUY_PACK";
})(SurveyCampaignType || (exports.SurveyCampaignType = SurveyCampaignType = {}));
var SurveyCampaignTypeTrans = exports.SurveyCampaignTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SurveyCampaignType.PracticeVideo, "Khảo sát tập thường"), SurveyCampaignType.PracticeAI, "Khảo sát tập với AI"), SurveyCampaignType.OpenApp, "Mở app"), SurveyCampaignType.Custom, "Custom"), SurveyCampaignType.SurveyBuyPack, "Khảo sát khi mua gói");