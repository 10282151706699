import { render, staticRenderFns } from "./RequestGiftModal.vue?vue&type=template&id=7dfd00a8"
import script from "./RequestGiftModal.vue?vue&type=script&lang=ts"
export * from "./RequestGiftModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7dfd00a8')) {
      api.createRecord('7dfd00a8', component.options)
    } else {
      api.reload('7dfd00a8', component.options)
    }
    module.hot.accept("./RequestGiftModal.vue?vue&type=template&id=7dfd00a8", function () {
      api.rerender('7dfd00a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/transaction/components/RequestGiftModal.vue"
export default component.exports