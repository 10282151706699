"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên form (Tiếng Việt)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameForm,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameForm", $$v);
      },
      expression: "form.nameForm"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên form (Tiếng Anh)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameFormEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameFormEn", $$v);
      },
      expression: "form.nameFormEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên form (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameFormJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameFormJp", $$v);
      },
      expression: "form.nameFormJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Câu hỏi (Tiếng Việt)",
      prop: "question"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.question,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "question", $$v);
      },
      expression: "form.question"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Câu hỏi (Tiếng Anh)",
      prop: "questionEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.questionEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "questionEn", $$v);
      },
      expression: "form.questionEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Câu hỏi (Tiếng Nhật)",
      prop: "questionJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.questionJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "questionJp", $$v);
      },
      expression: "form.questionJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Câu trả lời (Tiếng Việt)",
      prop: "quizChoices"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.quizChoices,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quizChoices", $$v);
      },
      expression: "form.quizChoices"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Câu trả lời (Tiếng Anh)",
      prop: "quizChoicesEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.quizChoicesEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quizChoicesEn", $$v);
      },
      expression: "form.quizChoicesEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Câu trả lời (Tiếng Nhật)",
      prop: "quizChoicesJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.quizChoicesJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quizChoicesJp", $$v);
      },
      expression: "form.quizChoicesJp"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Loại câu hỏi")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.typeQuestions, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.isRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isRequired", $$v);
      },
      expression: "form.isRequired"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "700"
    }
  }, [_vm._v(" Câu hỏi bắt buộc ")])])], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;