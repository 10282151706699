"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _format = require("@/utils/format");
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default2 = exports.default = {
  mixins: [_resize.default],
  props: {
    legend: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    format: {
      type: String,
      default: ""
    },
    xAxisValue: Array,
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    pointerName: {
      type: String,
      default: ""
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      type: String,
      default: "300px"
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      chart: null,
      initOptions: (0, _objectSpread2.default)({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: this.legend // string[]
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.xAxisValue,
          // string[]
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value"
        }],
        series: [{
          name: this.pointerName,
          type: "bar",
          stack: "vistors",
          barWidth: "60%",
          itemStyle: {
            color: _variables.default.primaryColor
          },
          data: this.data,
          animationDuration: animationDuration
        }]
      }, this.options)
    };
  },
  watch: {
    data: {
      handler: function handler(val) {
        this.initOptions.series[0].data = val;
        this.chart.setOption((0, _objectSpread2.default)({}, this.initOptions));
      }
    },
    xAxisValue: function xAxisValue(val) {
      this.initOptions.xAxis[0].data = val;
      this.chart.setOption((0, _objectSpread2.default)({}, this.initOptions));
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  methods: {
    setOptions: function setOptions(options) {
      this.chart.setOption((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.initOptions), options));
    },
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el);
      this.$emit("init:ok");
      this.chart.setOption(this.initOptions);
    }
  }
};