"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _vueHtmlToPaper = _interopRequireDefault(require("vue-html-to-paper"));
var options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    // "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    // "/quote-print.css",
    // "/receipt-print.css"
  ]
};
_vue.default.use(_vueHtmlToPaper.default, options);