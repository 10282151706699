"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.surveyCampaignAnswerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var surveyCampaignAnswerApi = exports.surveyCampaignAnswerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer",
      params: params
    });
  },
  findOneForm: function findOneForm(nameForm) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer"
    });
  },
  findOne: function findOne(nameForm) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer"
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer/".concat(id),
      method: "patch",
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer/".concat(id),
      method: "delete"
    });
  },
  publish: function publish(bookId, isPublished) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer/".concat(bookId, "/publish"),
      data: {
        isPublished: isPublished
      },
      method: "patch"
    });
  },
  public: function _public(bookId, isPublic) {
    return (0, _request.default)({
      url: "/v1/admin/surveyCampaignAnswer/".concat(bookId, "/public"),
      data: {
        isPublic: isPublic
      },
      method: "patch"
    });
  }
};