"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recordSaleApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var recordSaleApi = exports.recordSaleApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/recordSale",
      params: params
    });
  },
  findDetails: function findDetails(params) {
    return (0, _request.default)({
      url: "/v1/admin/recordSale/details",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/recordSale",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/recordSale/".concat(id),
      data: data,
      method: "patch"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/recordSale/".concat(id),
      method: "delete"
    });
  }
};