"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.string.ends-with.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm mới")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.surveyCampaigns,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Hình ảnh",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Link hình ảnh/video"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.image ? _c("div", [row.image.endsWith(".mp4") ? [_c("video", {
          staticStyle: {
            "border-radius": "4px",
            width: "200px"
          },
          attrs: {
            src: _vm.$url.image(row.image),
            type: "video/mp4"
          }
        })] : [_c("img", {
          staticStyle: {
            "border-radius": "4px",
            width: "200px"
          },
          attrs: {
            src: _vm.$url.image(row.image),
            alt: ""
          }
        })]], 2) : _vm._e()];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "Tiêu đề khảo sát"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Việt: ")]), row.title ? _c("span", [_vm._v(_vm._s(row.title))]) : _c("span", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Anh: ")]), row.titleEn ? _c("span", [_vm._v(_vm._s(row.titleEn))]) : _c("span", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Nhật: ")]), row.titleJp ? _c("span", [_vm._v(_vm._s(row.titleJp))]) : _c("span", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Các lựa chọn"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return _vm._l(row.surveyCampaignDetails, function (detail, index) {
          return _c("div", {
            key: index,
            staticStyle: {
              "margin-top": "10px",
              border: "1px solid #ccc",
              padding: "10px",
              "border-radius": "4px"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Lựa chọn tiếng Việt: ")]), detail.content ? _c("span", [_vm._v(_vm._s(detail.content))]) : _c("span", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Lựa chọn tiếng Anh: ")]), detail.contentEn ? _c("span", [_vm._v(_vm._s(detail.contentEn))]) : _c("span", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Lựa chọn tiếng Nhật: ")]), detail.contentJp ? _c("span", [_vm._v(_vm._s(detail.contentJp))]) : _c("span", [_vm._v("Chưa cập nhật")])])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Loại khảo sát",
      prop: "type",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(_vm.SurveyCampaignTypeTrans[row.type]))]), row.type == _vm.SurveyCampaignType.OpenApp ? _c("p", [_vm._v(" Từ " + _vm._s(_vm.$formatDateTime(row.startAt)) + " đến " + _vm._s(_vm.$formatDateTime(row.endAt)) + " ")]) : _vm._e(), row.type == _vm.SurveyCampaignType.OpenApp ? _c("el-switch", {
          attrs: {
            disabled: "",
            "active-color": "#13ce66",
            "inactive-color": "#ccc"
          },
          model: {
            value: row.isVisible,
            callback: function callback($$v) {
              _vm.$set(row, "isVisible", $$v);
            },
            expression: "row.isVisible"
          }
        }) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")]), row.type == _vm.SurveyCampaignType.Custom ? _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")]) : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchSurveyCampaign
    }
  }), _c("SurveyCampaignModal", {
    ref: "SurveyCampaignModal",
    on: {
      submitOk: _vm.fetchSurveyCampaign
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;