"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceTypeTrans = exports.DeviceType = exports.DeviceStatusTrans = exports.DeviceStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var DeviceType;
(function (DeviceType) {
  DeviceType["Mobile"] = "Handset";
  DeviceType["Tablet"] = "Tablet";
  DeviceType["TouchScreen"] = "TOUCH_SCREEN";
  DeviceType["Kios"] = "KIOSK";
})(DeviceType || (exports.DeviceType = DeviceType = {}));
var DeviceStatus;
(function (DeviceStatus) {
  DeviceStatus["Valid"] = "VALID";
  DeviceStatus["Invalid"] = "INVALID";
  DeviceStatus["Pending"] = "PENDING"; //Chờ duyệt
})(DeviceStatus || (exports.DeviceStatus = DeviceStatus = {}));
var DeviceStatusTrans = exports.DeviceStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DeviceStatus.Valid, 'Hợp lệ'), DeviceStatus.Invalid, 'Không hợp lệ'), DeviceStatus.Pending, 'Chờ duyệt');
var DeviceTypeTrans = exports.DeviceTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DeviceType.Tablet, 'Máy tính bảng'), DeviceType.TouchScreen, 'Touch Screen'), DeviceType.Kios, 'KIOSK');