"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestGiftApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var requestGiftApi = exports.requestGiftApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift/".concat(id),
      data: data,
      method: "patch"
    });
  },
  complete: function complete(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift/".concat(id, "/complete"),
      data: data,
      method: "patch"
    });
  },
  processing: function processing(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift/".concat(id, "/processing"),
      data: data,
      method: "patch"
    });
  },
  fail: function fail(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift/".concat(id, "/fail"),
      data: data,
      method: "patch"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/requestGift/".concat(id),
      method: "delete"
    });
  }
};