"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookTypeTrans = exports.BookType = exports.BookSyncFromTrans = exports.BookSyncFrom = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BookType;
(function (BookType) {
  BookType["Physical"] = "PHYSICAL";
  BookType["EBook"] = "E-BOOK";
  BookType["Product"] = "PRODUCT";
  BookType["SlimmingPillow"] = "SLIMMING_PILLOW";
})(BookType || (exports.BookType = BookType = {}));
var bookTypeTrans = exports.bookTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookType.Physical, "Sách giấy"), BookType.EBook, "Sách điện tử"), BookType.Product, "Sản phẩm vật lý"), BookType.SlimmingPillow, "Gói sản phẩm");
var BookSyncFrom;
(function (BookSyncFrom) {
  BookSyncFrom["Visedu"] = "VISEDU";
  BookSyncFrom["Batch"] = "BATCH";
  BookSyncFrom["Opac"] = "OPAC";
})(BookSyncFrom || (exports.BookSyncFrom = BookSyncFrom = {}));
var BookSyncFromTrans = exports.BookSyncFromTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookSyncFrom.Visedu, "Từ thư viện số Dspace"), BookSyncFrom.Batch, "Nhập theo lô"), BookSyncFrom.Opac, "Từ Sierra (Sách giấy)");