"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopupTypeTrans = exports.PopupType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var PopupType;
(function (PopupType) {
  PopupType["Link"] = "LINK";
  PopupType["Book"] = "BOOK";
  PopupType["Roadmap"] = "ROADMAP";
  PopupType["SurveyApp"] = "SURVEY_APP";
  PopupType["BuyPackage"] = "BUY_PACKAGE";
})(PopupType || (exports.PopupType = PopupType = {}));
var PopupTypeTrans = exports.PopupTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, PopupType.Link, "Liên kết ngoài"), PopupType.Book, "Vào chi tiết 1 bài"), PopupType.Roadmap, "Vào lộ trình"), PopupType.SurveyApp, "Vào form khảo sát người dùng"), PopupType.BuyPackage, "Vào trang mua gói");