"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keypointApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var keypointApi = exports.keypointApi = {
  get: function get(params) {
    return (0, _request.default)({
      url: '/v1/public/bookChapter/keypoint',
      params: params
    });
  },
  process: function process(data) {
    return (0, _request.default)({
      url: '/v1/admin/bookChapter/keypoint',
      data: data,
      method: 'post'
    });
  }
};