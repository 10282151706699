"use strict";

var _interopRequireDefault = require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerTypeTrans = exports.BannerType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/anhthai/Documents/GitHub/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BannerType;
(function (BannerType) {
  BannerType["Book"] = "BOOK";
  BannerType["BuyPackage"] = "BUY_PACKAGE";
  BannerType["ServicePack"] = "SERVICE_PACK";
  BannerType["PhysicalBook"] = "PHYSICAL_BOOK";
})(BannerType || (exports.BannerType = BannerType = {}));
var BannerTypeTrans = exports.BannerTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BannerType.Book, "Sách"), BannerType.BuyPackage, "Mua gói tập"), BannerType.ServicePack, "Gói sản phẩm"), BannerType.PhysicalBook, "Sản phẩm vật lý");