"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.string.link.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }), _c("section", [_c("h2", [_vm._v("Chung")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(_vm.ConfigurationTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _vm$getContentJson, _vm$getContentJson2, _vm$getContentJson3, _vm$getContentJson4, _vm$getContentJson5, _vm$getContentJson6, _vm$getContentJsonArr;
        var row = _ref2.row;
        return [row.type == _vm.ConfigurationType.SurveyAppText ? _c("div", [_c("div", [_c("label", {
          attrs: {
            for: "linkVi"
          }
        }, [_vm._v("Nội dung tiếng Việt: ")]), _c("span", [_vm._v(_vm._s((_vm$getContentJson = _vm.getContentJson(row.value)) === null || _vm$getContentJson === void 0 ? void 0 : _vm$getContentJson.link))])]), _c("div", [_c("label", {
          attrs: {
            for: "linkEn"
          }
        }, [_vm._v("Nội dung tiếng Anh: ")]), _c("span", [_vm._v(_vm._s((_vm$getContentJson2 = _vm.getContentJson(row.value)) === null || _vm$getContentJson2 === void 0 ? void 0 : _vm$getContentJson2.linkEn))])]), _c("div", [_c("label", {
          attrs: {
            for: "linkJp"
          }
        }, [_vm._v("Nội dung tiếng Nhật: ")]), _c("span", [_vm._v(_vm._s((_vm$getContentJson3 = _vm.getContentJson(row.value)) === null || _vm$getContentJson3 === void 0 ? void 0 : _vm$getContentJson3.linkJp))])])]) : row.type == _vm.ConfigurationType.AITutorialLink ? _c("div", [_c("div", [_c("label", {
          attrs: {
            for: "linkVi"
          }
        }, [_vm._v("Link tiếng Việt: ")]), _c("span", [_vm._v(_vm._s((_vm$getContentJson4 = _vm.getContentJson(row.value)) === null || _vm$getContentJson4 === void 0 ? void 0 : _vm$getContentJson4.link))])]), _c("div", [_c("label", {
          attrs: {
            for: "linkEn"
          }
        }, [_vm._v("Link tiếng Anh: ")]), _c("span", [_vm._v(_vm._s((_vm$getContentJson5 = _vm.getContentJson(row.value)) === null || _vm$getContentJson5 === void 0 ? void 0 : _vm$getContentJson5.linkEn))])]), _c("div", [_c("label", {
          attrs: {
            for: "linkJp"
          }
        }, [_vm._v("Link tiếng Nhật: ")]), _c("span", [_vm._v(_vm._s((_vm$getContentJson6 = _vm.getContentJson(row.value)) === null || _vm$getContentJson6 === void 0 ? void 0 : _vm$getContentJson6.linkJp))])])]) : row.type == _vm.ConfigurationType.PurchaseUnblockAllContent ? _c("div", [_c("div", [_c("label", {
          attrs: {
            for: "linkVi"
          }
        }, [_vm._v("Nội dung tiếng Việt: ")]), _c("br"), _vm._l(_vm.getContentJsonArray(row.value).content, function (item, index) {
          return _c("span", [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(item) + " "), _c("br")]);
        })], 2), _c("div", [_c("label", {
          attrs: {
            for: "linkEn"
          }
        }, [_vm._v("Nội dung tiếng Anh: ")]), _c("br"), _vm._l((_vm$getContentJsonArr = _vm.getContentJsonArray(row.value)) === null || _vm$getContentJsonArr === void 0 ? void 0 : _vm$getContentJsonArr.contentEn, function (item, index) {
          return _c("span", [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(item) + " "), _c("br")]);
        })], 2), _c("div", [_c("label", {
          attrs: {
            for: "linkJp"
          }
        }, [_vm._v("Nội dung tiếng Nhật: ")]), _c("br"), _vm._l(_vm.getContentJsonArray(row.value).contentJp, function (item, index) {
          return _c("span", [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(item) + " "), _c("br")]);
        })], 2)]) : row.dataType == "images" ? _c("div", [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            gap: "8px"
          }
        }, _vm._l(_vm.getContentJson(row.value), function (url) {
          return _c("img", {
            key: url,
            staticStyle: {
              "object-fit": "contain"
            },
            attrs: {
              src: url,
              width: "50px",
              height: "50px"
            }
          });
        }), 0)]) : row.dataType != "boolean" ? _c("span", [_vm._v(_vm._s(row.value))]) : _c("el-switch", {
          attrs: {
            value: row.value == "true",
            "active-color": "#13ce66",
            "inactive-color": "#ff4949"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1)], 1), _c("section", [_c("h2", [_vm._v("Web kiosk")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.faceDetectionConfigurations,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(_vm.ConfigurationTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s(row.value))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1), _c("small", [_vm._v(" Nếu vẫn nhận diện được " + _vm._s(_vm.faceDetectionTime) + " lần trong " + _vm._s(_vm.faceDetectionMinute) + " phút thì hết session sẽ logout người dùng; nếu không nhận diện được trong " + _vm._s(_vm.faceDetectionTimeOut) + " lần liên tiếp thì sẽ logout ")])], 1), _c("section", [_c("h2", [_vm._v("Lời nhắc việc")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.configReminders,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("span", [_vm._v(_vm._s(_vm.configReminderTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tiêu đề",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Việt: ")]), row.title ? _c("span", [_vm._v(_vm._s(row.title))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Anh: ")]), row.titleEn ? _c("span", [_vm._v(_vm._s(row.titleEn))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Nhật: ")]), row.titleJp ? _c("span", [_vm._v(_vm._s(row.titleJp))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nội dung"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Việt: ")]), row.content ? _c("span", [_vm._v(_vm._s(row.content))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Anh: ")]), row.contentEn ? _c("span", [_vm._v(_vm._s(row.contentEn))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Nhật: ")]), row.contentJp ? _c("span", [_vm._v(_vm._s(row.contentJp))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdateConfigReminder(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1)], 1), _c("ConfigurationModal", {
    ref: "ConfigurationModal",
    on: {
      submitOk: function submitOk() {
        _vm.fetchData();
        _vm.fetchFaceDetectionConfiguration();
      }
    }
  }), _c("ConfigReminderModal", {
    ref: "ConfigReminderModal",
    on: {
      submitOk: function submitOk() {
        _vm.fetchConfigReminder();
      }
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;