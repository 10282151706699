"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
      visible: _vm.dialogVisible,
      width: "700px",
      top: "15px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dialogLoading,
      expression: "dialogLoading"
    }]
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-width": "80px",
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh tiếng Việt (400x200, tỷ lệ 2:1)",
      prop: "image"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.formData.image,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": function uploadSuccess(event) {
        return _vm.handleUploadSuccess(event, "image");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hình ảnh tiếng Anh (400x200, tỷ lệ 2:1)",
      prop: "imageEn"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.formData.imageEn,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": function uploadSuccess(event) {
        return _vm.handleUploadSuccess(event, "imageEn");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hình ảnh tiếng Nhật (400x200, tỷ lệ 2:1)",
      prop: "imageJp"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.formData.imageJp,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": function uploadSuccess(event) {
        return _vm.handleUploadSuccess(event, "imageJp");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề tiếng Việt",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề tiếng Anh",
      prop: "titleEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.titleEn,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "titleEn", $$v);
      },
      expression: "formData.titleEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề tiếng Nhật",
      prop: "titleJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.titleJp,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "titleJp", $$v);
      },
      expression: "formData.titleJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "App"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      clearable: "",
      remote: "",
      "remote-method": _vm.fetchMobileApp
    },
    model: {
      value: _vm.formData.mobileAppIds,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "mobileAppIds", $$v);
      },
      expression: "formData.mobileAppIds"
    }
  }, _vm._l(_vm.mobileApps, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.appName,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Loại"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      "remote-method": _vm.fetchBooks
    },
    on: {
      change: _vm.changeType
    },
    model: {
      value: _vm.formData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "type", $$v);
      },
      expression: "formData.type"
    }
  }, _vm._l(_vm.BannerTypeTrans, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: index
      }
    });
  }), 1)], 1), _vm.formData.type !== _vm.BannerType.BuyPackage ? _c("el-form-item", {
    attrs: {
      label: _vm.generateLabelBook()
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      "remote-method": _vm.fetchBooks
    },
    model: {
      value: _vm.formData.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "bookId", $$v);
      },
      expression: "formData.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      loading: _vm.submitLoading,
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogStatus == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;